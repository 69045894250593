<template>
  <div>
    <center>
    <h2>
      Please wait while processing result.
    </h2>
 <CSpinner
      style="width:4rem;height:4rem;"
      grow
    />
    </center>

  </div>
</template>

<script>
import { localDb } from "@/mixins/localDb";
import { ajaxCallMixin } from "@/mixins/HttpCommon";

export default {
  name: "ExamFeedback",
  mixins: [ajaxCallMixin, localDb],
  data() {
    return {
      feedBackQuestions: [
        {
          question:
            "Has there been any malpractice of any kind during this assessment?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question:
            "Has the assessor asked for any monetory bribe or any other kind of favor?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question:
            "Did the assessor check all original records, data, identity cards and beneficiaries?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question: "Did the assessor arrive on time?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question:
            "Did the assessor follow the prescribed procedures for conducting assessment?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question: "Was the assessor aware of the job role?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question: "Was the viva conducted in a cordial manner?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question:
            "Did the assessor possess practical knowledge of the job role?",
          response: "1",
          options: [
            { value: "0", label: "No" },
            { value: "1", label: "Yes" },
          ],
        },
        {
          question: "What aspects of the Assessments could be improved?",
          response: "",
          options: [
            { value: "0", label: "Online Test" },
            { value: "1", label: "Viva" },
            { value: "2", label: "Practical" },
            { value: "3", label: "N/A" },
          ],
        },
        {
          question: "Overall conduct of the Assessment and Assessor?",
          response: "",
          options: [
            { value: "0", label: "Needs Improvement" },
            { value: "1", label: "Good" },
          ],
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    const status = JSON.parse(sessionStorage.getItem("isExamInProgress"));
    if (status) next({ path: "/exam/paper" });
    else next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "ExamPaper") next(false);
    else next();
  },
  created(){
    this.handleSubmit();
  },
  methods: {
    handleSubmit() {
      let examData = this.get("examData", "local");
      let candidateResponse = this.get("candidateResponse", "session");
      let testObject = {};
      testObject.candidateResponse = candidateResponse;
      testObject.moduleDetails = examData.moduleData;
      let data = {};
      data.testObject = testObject;
      data.feedBackData = this.feedBackQuestions;
      data.registrationData = [];
      data.isCompleted = "6";
      let params = {};
      params.data = data;
      params.language = this.currentLangCode;
      params.token = examData.authToken;
      let url = "/test/test/saveTestObject";
      this.ajaxCall(url, params, this.callBackSubmit);
    },
    callBackSubmit(apiResponse) {
      if (apiResponse.error !== true) {
        
        this.$router.push("/exam/result");
      }
    },
  },
};
</script>
